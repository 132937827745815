<script setup lang="ts">
import { useStorage } from '@vueuse/core'

const isOpen = ref(false)
let resolveConfirm: ((value: boolean) => void) | null = null

async function confirm() {
  if (skipGoogleSheetConfirm.value) {
    return true
  }

  isOpen.value = true
  return new Promise<boolean>((resolve) => {
    resolveConfirm = resolve
  })
}

function handleConfirm(value: boolean) {
  isOpen.value = false
  if (resolveConfirm) {
    resolveConfirm(value)
    resolveConfirm = null
  }
}

defineExpose({
  confirm,
})

const runtimeConfig = useRuntimeConfig()
const skipGoogleSheetConfirm = useStorage<boolean>('forgd:nav:google-sheet-confirm', false)
</script>

<template>
  <UiModal :is-open="isOpen" @close="handleConfirm(false)">
    <div>
      <UiStack>
        <div>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="mb-5">
            <path d="M33.3332 11.5701V32.6666C33.3332 34.8757 31.5423 36.6666 29.3332 36.6666H10.6665C8.45737 36.6666 6.6665 34.8757 6.6665 32.6666V7.33325C6.6665 5.12411 8.45737 3.33325 10.6665 3.33325H24.3507M33.3332 11.5701L24.3507 3.33325M33.3332 11.5701H24.3507V3.33325M20.1402 19.2921H12.2805V23.2818M20.1402 19.2921H27.9998V23.2818M20.1402 19.2921V27.2715M20.1402 27.2715H27.9998V23.2818M20.1402 27.2715H12.2805V23.2818M27.9998 23.2818H12.2805" stroke="#057A55" stroke-width="1.5" />
          </svg>
          <div class="mb-3 font-semibold text-xl text-primary-900">
            You are about to open a Forgd workbook in Google Sheets in a new tab
          </div>
          <div class="mb-2 text-gray-600 text-base">
            Create a copy of the file on your local device (File > Make a Copy) before editing. Your session on Forgd will remain active.
          </div>
          <div class="text-gray-600 text-base">
            Would you like to continue?
          </div>
        </div>
        <div class="flex items-center w-full gap-3">
          <UiButton variant="outline" class="w-1/2" @click="handleConfirm(false)">
            Cancel
          </UiButton>
          <UiButton variant="solid" class="w-1/2" @click="handleConfirm(true)">
            Yes, Continue
          </UiButton>
        </div>
        <UiRule />
        <UCheckbox v-model="skipGoogleSheetConfirm" label="Don't show this message again" />
        <UiAlert type="info">
          <template #title>
            If you have questions on how to edit the prototype, <NuxtLink :to="runtimeConfig.public.consultationUrl" target="_blank" class="underline text-forgd-primary-300">
              schedule a consultation
            </NuxtLink>.
          </template>
        </UiAlert>
      </UiStack>
    </div>
  </UiModal>
</template>
